.App {
    font-family: sans-serif;
    text-align: center;
  }

.leaflet-div-icon {
  background: transparent;
  border: none;
}
.MuiBox-root .leaflet-container {
  width: 100wh;
  height: 93vh;
}